<template>
  <v-container fluid style="height: calc(100vh - 48px); position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4 mb-7">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'roles'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1>{{ $t(`middleware.role.form.create`) }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            elevation="2"
            color="green"
            dark
            medium
            class="ml-auto"
            @click="save()"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-6">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  hide-details
                  label="Name"
                  placeholder="Name"
                  v-model="form.name"
                  :error="errors.name!==undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  hide-details
                  label="Display name"
                  placeholder="Display name"
                  v-model="form.display_name"
                  :error="errors.display_name!==undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  hide-details
                  label="Description"
                  placeholder="Description"
                  v-model="form.description"
                  :error="errors.description!==undefined"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-6">
          <v-text-field
              outlined
              hide-details
              label="Suche"
              placeholder="Suche..."
              v-model="search"
          ></v-text-field>
          <v-row class="mt-4">
            <v-col cols="12" md="4" lg="3" v-for="(permission, name, index) in getPer" :key="index">
              <v-card class="mx-card-role pa-3 pb-6" style="height: 100%">
                <v-card-title>{{ ucFirst(name) }}</v-card-title>
                <v-checkbox
                    v-for="(item, index) in permission"
                    :key="index"
                    :input-value="checkin(item.id)"
                    class="ml-3"
                    :label="item.display_name"
                    hide-details
                    @click="checken(item.id)"
                ></v-checkbox>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      preloader: true,
      permissions: [],
      search: '',
      form: {
        name: '',
        display_name: '',
        description: '',
        permissionSelected: []
      },
      errors: []
    }
  },
  computed: {
    getPer() {
      return Object.keys(this.permissions).filter(key => {
        return key.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      }).reduce((obj, key) => {
        obj[key] = this.permissions[key];
        return obj;
      }, {});
    }
  },
  mounted() {
    this.$store.dispatch('permissionsLaratrust').then(() => {
      this.permissions = this.$store.getters.permissions_laratrust
      this.preloader = false
    })
  },
  methods: {
    checken(id) {
      let index = this.form.permissionSelected.indexOf(id)

      if (index !== -1) {
        this.form.permissionSelected.splice(index, 1)
      } else {
        this.form.permissionSelected.push(id)
      }
    },
    checkin(id) {
      return this.form.permissionSelected.indexOf(id) !== -1
    },
    save() {
      this.itemLowerCase()
      this.$store.dispatch('roleCreateLaratrust', this.form).then(() => {
        this.$router.replace({
          name: 'roles'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.btn_create_loading = false
            break;
        }
      })
    },
    ucFirst(str) {
      if (!str) return str;
      return str[0].toUpperCase() + str.slice(1);
    },
    itemLowerCase() {
      this.form.name = this.form.name.toLowerCase()
    }
  }
}
</script>
